import React from 'react';
import { Link } from 'react-scroll';

import jflGfmIT from '../../assets/images/jfl-GfmIT_pseDQ.jpg';
import samMcghee from '../../assets/images/sam-mcghee-4siwRamtFAk.jpg';
import austinDistel from '../../assets/images/austin-distel-rxpThOwuVgE.jpg';
import mattBotsford from '../../assets/images/matt-botsford-OKLqGsCT8qs.jpg';
import logo from '../../assets/images/r_a.png';

const Footer = () => {
	return (
		<footer id="news">
			{/* <div className="container">
				<div className="row align-items-end">
					<div className="col-md-7">
						<div className="title-section__light small-size">
							<h2>Studio in the News.</h2>
							<span className="title-section__background">News</span>
						</div>
					</div>

					<div className="col-md-5 text-right">
						<a className="btn btn-primary btn-sm simple-animation" href="blog.html" role="button"><span>See BLog</span></a>
					</div>
				</div>

				<div className="row mt-5 latest-post">
					<div className="latest-post-item">
						<a href="blog.html">
							<img src={jflGfmIT} alt="" className="w-100" />
							<h5 className="latest-post__title">Trends</h5>
							<p>Explore the usage of metallics in design and discover the ground rules for navigating the trend.</p>
						</a>
					</div>

					<div className="latest-post-item">
						<a href="blog.html">
							<img src={samMcghee} alt="" className="w-100" />
							<h5 className="latest-post__title">Interviews</h5>
							<p>Our new course offers over an hour of great tips on professional voice recording.</p>
						</a>
					</div>

					<div className="latest-post-item">
						<a href="blog.html">
							<img src={austinDistel} alt="" className="w-100" />
							<h5 className="latest-post__title">General</h5>
							<p>There’s no typical start time for a ‘normal’ day working for the staff of Envato.</p>
						</a>
					</div>

					<div className="latest-post-item">
						<a href="blog.html">
							<img src={mattBotsford} alt="" className="w-100" />
							<h5 className="latest-post__title">Tools</h5>
							<p>Breaking down one of the biggest video trends of the year.</p>
						</a>
					</div>
				</div>
			</div> */}

			{/* <div className="row">
				<hr className="light-color" />
			</div> */}

			<div className="footer-content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 col-sm-4 text-sm-left text-center">
							<Link href="/" to="home" spy={true} smooth={true} duration={500}>
								<img src={logo} alt="logo" className="logo" />
							</Link>
						</div>

						<div className="col-12 col-sm-4 text-sm-left text-center">
							<ul className="p-0 m-0 footer-links">
								<li><Link href="/#about" to="about" spy={true} smooth={true} duration={500}>About</Link></li>
								<li><Link href="/#services" to="services" spy={true} smooth={true} duration={500}>Services</Link></li>
								<li><Link href="/#work" to="work" spy={true} smooth={true} duration={500}>What We Do?</Link></li>
							</ul>
						</div>

						<div className="col-12 col-sm-4 text-sm-left text-center">
							<h4 className="footer__title-link">Contact</h4>
							<ul className="p-0 m-0 footer-links">
								<li><a href="mailto:hello@reactassistant.com" title="Footer Links">Send us a Message</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};


export default Footer;