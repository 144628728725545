import React from 'react';
import Slider from "react-slick";

import bradNeathery from '../../assets/images/brad-neathery-mGH253KbfaY.jpg';
import adultsBrainstormingBusiness from '../../assets/images/adults-brainstorming-business.jpg';
import halGatewood from '../../assets/images/hal-gatewood-tZc3vjPCk-Q.jpg';

const ProcessSection = () => {

	const sliderSettings = {
		infinite: true,
		autoplay: true,
		autoplaySpeed: 10000,
		speed: 500,
		arrows: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		adaptiveHeight: true,
		responsive: [
			{
			  breakpoint: 800,
			  settings: {
				slidesToShow: 2
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 1
			  }
			}
		]
	};

	return (
		<section className="works" id="work"> 
			<div className="container">
				<div className="row">
					<div className="col-lg-10">
						<div className="title-section__dark">
							<h2>What We Do?</h2>
							<span className="title-section__background">What We Do?</span>
							<p>Our front end developers have one general responsibility: to ensure that website visitors can easily interact with the page. They do this through the combination of design, technology and programming to code a website’s appearance, as well as taking care of debugging.</p>
						</div>
						<div className="title-section__dark small-size mb-3">
							<h3>Our Common Tasks</h3>
							<ul className="make-list dark">
								<li>Optimizing the user experience.</li>
								<li>Using HTML, JavaScript and CSS to bring concepts to life.</li>
								<li>Developing and maintaining the user interface.</li>
								<li>Implementing design on mobile websites.</li>
								<li>Creating tools that improve site interaction regardless of the browser.</li>
								<li>Managing software workflow.</li>
								<li>Following SEO best practices.</li>
								<li>Fixing bugs and testing for usability.</li>
							</ul>
						</div>
						<div className="title-section__dark small-size">
							<h3>Commonly Used Programming Languages</h3>
							<p className="mb-3">We spend a great deal of their time working in HTML, CSS and JavaScript, making proficiency in each key to their success.</p>

							<div className="mb-3">
								<h4>How We Use Each Programming Language</h4>
								<p>We use HTML to lay out a document’s general structure and content, CSS for styling and JavaScript for situations that require advanced interactivity. Additionally, we might use AJAX (a combination of JavaScript and XML) to update specific areas of a website without having to refresh the entire page.</p>
							</div>

							<div className="mb-3">
								<h4>Libraries and Frameworks</h4>
								<p>Commonly we use the libraries built on these programming languages like React, Angular and jQuery. And design framework Bootstrap. CSS extensions, such as SASS, provide improved modularity and power.</p>
							</div>

							<div className="mb-3">
								<h4>Using CSS Preprocessors</h4>
								<p>Our front end developers use SASS preprocessor to add functionality to CSS coding, making it more scalable and easier to interact with. Prior to publishing the code on your website, SASS transition it into well-formatted CSS that works across a variety of browsers.</p>
							</div>

							<div className="mb-3">
								<h4>Using APIs and RESTful Services</h4>
								<p>Our front end developer will also interact with and make use of APIs and RESTful services. REST (Representational State Transfer) is a lightweight architecture that makes network communications simpler, while APIs and RESTful services follow that architecture.</p>
							</div>

							<div className="mb-3">
								<h4>Creating Maintaining Mobile and Responsive Design</h4>
								<p className="mb-2">With the rise of people using mobile devices to connect to the internet, it has become essential for websites to be mobile-friendly. As such, our front end developers create responsive designs or mobile designs for their websites. </p>
								<p>Responsive design changes a website’s layout depending on the device and screen size, and occasionally requiring changes to the content and functionality based on those factors.</p>
							</div>

							<div>
								<h4>Developing Across Browsers</h4>
								<p>If your web development isn’t functional across the full range of browsers that are available today, you will miss out on an entire category of potential web users. While browsers are fairly consistent, their differences can be significant, including in terms of coding interpretation. Our front end web developers understand these differences and incorporate them into code.</p>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<hr className="dark-color"/>
				</div>

				{/* <div className="row align-items-center pb-5">
					<div className="follow-us__text">
						<p>Do you want to see more? <a href="#">Follow Us:</a> </p>
					</div>

					<div className="follow-us__social-links text-md-right">
						<a href="#"><i className="fab fa-youtube"></i></a>
						<a href="#"><i className="fab fa-instagram"></i></a>
						<a href="#"><i className="fab fa-linkedin-in"></i></a>
						<a href="#"><i className="fab fa-facebook-f"></i></a>
						<a href="#"><i className="fab fa-twitter"></i></a>
					</div>
				</div> */}
			</div>

			<div className="works--done_">
				<div className="container-fluid">
					<div className="d-none d-xl-block">
						<div className="row">
							<div className="col-md-3">
								<figure className="works--done__item">
									<img src={halGatewood} alt="" />
									<figcaption className="works--done__item-description">
										<h3>PLANNING</h3>
										<p>During this stage scope is defining and solutions are planning, estimating cost and efforts, identify risks and benefits.</p>
									</figcaption>
								</figure>
							</div>

							<div className="col-md-3">
								<figure className="works--done__item">
									<img src={bradNeathery} alt="" />
									<figcaption className="works--done__item-description">
										<h3>DESIGN</h3>
										<p>Elaborate core specifications, features, functions and flows to meet requrement and create modern UI/UX design.</p>
									</figcaption>
								</figure>
							</div>

							<div className="col-md-3">
								<figure className="works--done__item">
									<img src={adultsBrainstormingBusiness} alt="" />
									<figcaption className="works--done__item-description">
										<h3>IMPLEMENTATION</h3>
										<p>Actual development begins here following the design and requirements. Code reviews and unit testing are done.</p>
									</figcaption>
								</figure>
							</div>

							<div className="col-md-3">
								<figure className="works--done__item">
									<img src={adultsBrainstormingBusiness} alt="" />
									<figcaption className="works--done__item-description">
										<h3>INTEGRATION & TESTING</h3>
										<p>QA team tests the software for errors and bugs, determines if the solutions meets the requirements.</p>
									</figcaption>
								</figure>
							</div>
						</div>
					</div>
				</div>

				<div className="works--done-carousel d-xl-none">
					<Slider {...sliderSettings}>
						<figure className="works--done__item">
							<img src={halGatewood} alt="" />
							<figcaption className="works--done__item-description">
								<h3>PLANNING</h3>
								<p>During this stage scope is defining and solutions are planning, estimating cost and efforts, identify risks and benefits.</p>
							</figcaption>
						</figure>

						<figure className="works--done__item">
							<img src={bradNeathery} alt="" />
							<figcaption className="works--done__item-description">
								<h3>DESIGN</h3>
								<p>Elaborate core specifications, features, functions and flows to meet requrement and create modern UI/UX design.</p>
							</figcaption>
						</figure>

						<figure className="works--done__item">
							<img src={adultsBrainstormingBusiness} alt="" />
							<figcaption className="works--done__item-description">
								<h3>IMPLEMENTATION</h3>
								<p>Actual development begins here following the design and requirements. Code reviews and unit testing are done.</p>
							</figcaption>
						</figure>

						<figure className="works--done__item">
							<img src={adultsBrainstormingBusiness} alt="" />
							<figcaption className="works--done__item-description">
								<h3>INTEGRATION & TESTING</h3>
								<p>QA team tests the software for errors and bugs, determines if the solutions meets the requirements.</p>
							</figcaption>
						</figure>
					</Slider>
				</div>
			</div> 
		</section>
	);
};

export default ProcessSection;