import React, { useState } from 'react';
import logo from '../../assets/images/r_a.png';
import { Link } from 'react-scroll';
import clsx from 'clsx';

const Header = () => {
	const [isMobileOpened, onToggleMobyleMenu] = useState(false);

	const handleOpenMenu = () =>  {
		onToggleMobyleMenu(true);
		document.body.classList.add('modal-open');
	};

	const handleCloseMenu = () =>  {
		onToggleMobyleMenu(false);
		document.body.classList.remove('modal-open');
	};

	return (
		<>
			<div className="menu-sticked">
				<header>
					<div className="container">
						<div className="row align-items-center">
							<div className="logo">
								<Link href="/" to="home" spy={true} smooth={true} duration={500}>
									<img src={logo} alt="logo" />
								</Link>
							</div>

							<nav className="col-md-9">
								<div className="d-none d-lg-block">
									<ul>
										<li><Link href="/#work" to="work" spy={true} smooth={true} duration={500}>What We Do?</Link></li>
										<li><Link href="/#services" to="services" spy={true} smooth={true} duration={500}>Services</Link></li>
										<li><Link href="/#about" to="about" spy={true} smooth={true} duration={500}>About</Link></li>
										<li><Link href="/#contact" to="contact" spy={true} smooth={true} duration={500}>Contact</Link></li>
										<li className="d-none d-sm-block">
											<div className="phone">
												<a className="btn btn-primary btn-sm" href="tel:+37493120458" role="button"><span>(+374)93 12 04 58</span></a>
											</div>
										</li>
									</ul>
								</div>

								<button type="button" className="mobile-nav d-lg-none" onClick={handleOpenMenu}>
									<i className="fas fa-align-right"></i>
								</button>
							</nav>
						</div>
					</div>
				</header>
			</div>

			{/* Mobile Menu */}
			<div className={clsx('modal fade mobile-menu', isMobileOpened && 'show')}>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="close" onClick={handleCloseMenu}>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<nav className="mobile-items text-center">
								<ul>
									<li><Link href="/#work" to="work" spy={true} smooth={true} duration={500} onClick={handleCloseMenu}>What We Do?</Link></li>
									<li><Link href="/#services" to="services" spy={true} smooth={true} duration={500} onClick={handleCloseMenu}>Services</Link></li>
									<li><Link href="/#about" to="about" spy={true} smooth={true} duration={500} onClick={handleCloseMenu}>About</Link></li>
									<li><Link href="/#contact" to="contact" spy={true} smooth={true} duration={500} onClick={handleCloseMenu}>Contact</Link></li>
									<li>
										<div className="phone">
											<a className="btn btn-primary btn-sm" href="tel:+37493120458" role="button" onClick={handleCloseMenu}><span>(+374)93 12 04 58</span></a>
										</div>
									</li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
			{isMobileOpened ?
				<div className={clsx('modal-backdrop fade show')}></div> : null
			}
		</>
	);
};


export default Header;