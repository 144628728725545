import React from 'react';

const HomeSection = () => {
	return (
		<section className="hero-home" id="home">
			<div className="container">
				<div className="row">
					<div className="hero-home__container">
						<div className="hero-home__container-centered d-flex justify-content-center">
							<div className="text-center">
								<div className="hero-home__title">
									We are<span className="hero-home__text-biger d-block text-uppercase">React</span>
									<span className="hero-home__text-big d-block text-uppercase">Assistant</span>
								</div>
								<hr className="light-color" />
								<h1 className="hero-home__description">Digital transformation consultancy and engineering <br />company that provide high  quality Frontend Services</h1>
								<a className="btn btn-outline-primary btn-lg mt-4" href="mailto:hello@reactassistant.com"><span>Talk with Us</span></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HomeSection;