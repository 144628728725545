import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getPosts, getUsers } from './store/actions';
import Header from './components/Header/Header';
import Home from './containers/Home/Home';
import Loader from './components/UI/Loader/Loader';

const App = ({ loading, onGetPosts, onGetUsers }) => {

  // useEffect(() => {
  //   onGetPosts();
  //   onGetUsers();
  // }, [onGetPosts, onGetUsers]);

  return (
    <div className="main-wrapper">
      <div className="main-container">
        {loading ?
          <Loader /> :
          <>
            <Header />
            <Home />
          </>
        }
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.posts.loading || state.users.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetPosts: () => dispatch(getPosts()),
    onGetUsers: () => dispatch(getUsers())
  };
};

App.propTypes = {
  loading: PropTypes.bool,
  onGetPosts: PropTypes.func,
  onGetUsers: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
