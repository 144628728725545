import React from 'react';
import Slider from "react-slick";

const ContactSection = () => {
	const settings = {
		dots: true,
		fade: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 5000,
		speed: 500,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1
	};

	return (
		<section className="contact" id="contact">
			<div className="container">
				<div className="row d-flex justify-content-center">
					<div className="col-md-6">
					<div className="title-section__dark text-center">
						<h2>Contact!</h2>
					</div>
					</div>
				</div>
				
				<div className="row">
					<hr className="dark-color" />
				</div>
				
				<div className="row justify-content-center">
					<div className="contact__description">
					<p>We're always looking for new projects to work on, <br />if you have some please write us!</p>

					<span className="contact__mail d-block my-4"><a href="mailto:hello@reactassistant.com" >hello@reactassistant.com</a></span>
					</div>
				</div>
			
				<div className="row">
					<hr className="dark-color" />
				</div>
			
				<div className="row mb-5">
					<div className="contact__phone">
						<i className="far fa-comment-alt"></i>
						<a href="tel:0000000" className="d-block mt-3">(+374)93 12 04 58</a>
					</div>
					
					<div className="contact__location">
						<i className="fas fa-map-marker-alt"></i>
						<address>
							<span className="d-block mt-4">We're at Armenia!</span>
						</address>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-10">
						<div className="title-section__dark">
							<h2>Testimonials</h2>
							<span className="title-section__background">Testimonials</span>
						</div>
					</div>
				</div>

				<div className="row d-flex justify-content-center">
					<div className="col-lg-9">
						<div className="testimonials">
							<Slider {...settings}>
								<div className="testimonials__content">
									<blockquote className="blockquote mb-0">
										<p>The project has flowed smoothly between the teams, leading to continued collaboration. With added QA efforts being the only suggestion for improvement, we are satisfied by ReactAssistant’s work.</p>
										<div className="blockquote-footer">John Spencer</div>
									</blockquote>
								</div>

								<div className="testimonials__content">
									<blockquote className="blockquote mb-0">
										<p>Support is amazing. I had a problem and support fixed it immediately. I can't thank them enough.</p>
										<div className="blockquote-footer">Michael King</div>
									</blockquote>
								</div>

								<div className="testimonials__content">
									<blockquote className="blockquote mb-0">
										<p>Overall, we’re very happy with the work and we’re going to continue working with them.</p>
										<div className="blockquote-footer">Martin Williams</div>
									</blockquote>
								</div>

								<div className="testimonials__content">
									<blockquote className="blockquote mb-0">
										<p>ReactAssistant have very fast and precise frontend development team.</p>
										<div className="blockquote-footer">Thomas Robinson</div>
									</blockquote>
								</div>
							</Slider>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactSection;