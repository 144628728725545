import React, { useRef } from 'react';
import Slider from "react-slick";

import serviceIll1 from '../../assets/images/services_ill_1.png';
import serviceIll2 from '../../assets/images/services_ill_2.png';
import serviceIll3 from '../../assets/images/services_ill_3.png';
import serviceIll4 from '../../assets/images/services_ill_4.png';
import serviceIll5 from '../../assets/images/services_ill_5.png';

const ServicesSection = () => {
	const sliderImgRef = useRef();
	const sliderImgSettings = {
		infinite: true,
		fade: true,
		speed: 500,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1
	};
	const sliderSettings = {
		infinite: true,
		autoplay: true,
		autoplaySpeed: 5000,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		afterChange: () => sliderImgRef.current.slickNext(),
		responsive: [
			{
			  breakpoint: 800,
			  settings: {
				slidesToShow: 2
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 1
			  }
			}
		]
	};

	return (
		<>
			{/* <!-- Services --> */}
			<section className="service" id="services">
				<div className="service--container">
					<div className="container">
						<div className="row">
							<div className="col-lg-10">
								<div className="title-section__light">
									<h2>Services</h2>
									<span className="title-section__background">Services</span>
									<p>React Assistant is highly specialized in the front-end direction. We create a design and translate it into html/css code.</p>
									<p>
										If necessary, we create a full-fledged front-end on React and React-native. <br />
										We help our clients in the front-end part - both in creating a project from scratch, and continuing, correcting and/or changing the project. 
									</p>
									<p>We work with individuals and with companies as well.</p>
									<p>We do our work with love - we do it in such a way that both the client and ourselves are satisfied.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Services --> */}

			{/* <!-- Services Carousel --> */}
			<section className="services-description">
				<div className="container">
					<div className="services-description__screen">

						<div className="slider-for-services">
							<Slider ref={sliderImgRef} {...sliderImgSettings}>
								<div><img src={serviceIll1} alt="Code" /></div>
								<div><img src={serviceIll2} alt="Coding" /></div>
								<div><img src={serviceIll3} alt="Coding" /></div>
								<div><img src={serviceIll4} alt="Code" /></div>
								<div><img src={serviceIll5} alt="Keyboard" /></div>
							</Slider>
						</div>

					</div>
				</div>


				<div className="container">
					<div className="services-description__list">
						<div className="slider-nav-services">
							<Slider {...sliderSettings}>
								<div>
									<i className="fas fa-adjust"></i>
									<h4 className="services-description__title">Design</h4>
									<p className="services-description__">Elaborate core specifications, features, functions and flows to meet requirement and create modern UI/UX design</p>
								</div>

								<div>
									<i className="fas fa-anchor"></i>
									<h4 className="services-description__title">Markup Development</h4>
									<p className="services-description__">Markup development is a piece of cake for us. Thousands of days working on markup tasks allowed us to reach expertise in this field. Responsive HTML, PSD to Bootstrap, PSD to HTML and similar tasks are nothing new for us.</p>
								</div>

								<div>
									<i className="fas fa-brain"></i>
									<h4 className="services-description__title">Layout Creating</h4>
									<p className="services-description__">We devote most of our working hours to beautiful layouts. What we prefer are tasks connected to huge and complex layouts–we know how to build and optimize them for your website.</p>
								</div>

								<div>
									<i className="fas fa-adjust"></i>
									<h4 className="services-description__title">Animation Building</h4>
									<p className="services-description__">We have vast experience of working with complicated animated websites. No matter what platform or device you would like your website to run on, we can make it real for you.</p>
								</div>

								<div>
									<i className="fas fa-bug"></i>
									<h4 className="services-description__title">Support</h4>
									<p className="services-description__">We provide support for any future upgrades and bug fixes</p>
								</div>
							</Slider>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Services Carousel --> */}
		</>
	);
};

export default ServicesSection;