import React from 'react';

import Mos from '../../assets/images/Mos.jpg';
import Hak from '../../assets/images/Hak.jpg';
import Syuz from '../../assets/images/Syuz.jpg';
import Lus from '../../assets/images/Lus.jpg';
import icon1 from '../../assets/images/icon1.png';
import icon2 from '../../assets/images/icon2.png';
import icon3 from '../../assets/images/icon3.png';
import icon4 from '../../assets/images/icon4.png';
import icon5 from '../../assets/images/icon5.png';

import iconReact from '../../assets/images/icons/icon-react.png';
import iconNextjs from '../../assets/images/icons/icon-nextjs.png';
import iconHtml from '../../assets/images/icons/icon-html.png';
import iconCss from '../../assets/images/icons/icon-css.png';
import iconJs from '../../assets/images/icons/icon-js.png';
import iconRedux from '../../assets/images/icons/icon-redux.png';
import iconBs from '../../assets/images/icons/icon-bs.png';

const AboutSection = () => {

	return (
		<section className="about" id="about">
			<div className="about--container">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="title-section__light">
								<h2>About React Assistant</h2>
								<p>We are a digital transformation consultancy and engineering company that delivers cutting edge solutions for global organisations, technology startups and individual customers.</p>
								<p>We have a wide network of professionals around the world in order to be closer to our clients and offer resources in the same time zone +/- 2 hours, as well as have a flexible pricing policy for our clients.</p>
							</div>
							<div className="title-section__light">
								<h2>Mission & Vision</h2>
								<p>React Assistant, first of all, is a platform where ingenious ideas that are changing our world meet talents who are ready to bring them to life with their experience, talent, and hard work.</p>
								<p>This is a platform that knows no boundaries and limitations, where every specialist is appreciated, where all work is not built on the perseverance of employees but is optimized by unique working algorithms to achieve maximum results, meet the expectations of customers and employees, and provide service for reasonable prices.</p>
								<p>Our extensive programming experience opens up opportunities for the most incredible projects and ideas, covering any available platform for maximum success in all areas of these projects.</p>
								<p>We always keep our finger on the pulse of innovation and actively introduce and apply new innovative practices in our daily work in order to achieve maximum results.</p>
								<p>Keep up with us!</p>
							</div>
						</div>
					</div>
					
					<div className="row">
						<hr className="light-color" />
					</div>
					
					<div className="team">
						<div className="row">
							<div className="team__member text-center">
									<img className="team__avatar" src={Mos} alt="Movses Mesropyan" />
									<h3 className="team__name mt-3">Movses Mesropyan</h3>
									<p className="team__ocupation">CEO and founder<br />Front-end developer</p>
							</div>

							<div className="team__member text-center">
								<img className="team__avatar" src={Syuz} alt="Syuzanna Hakhverdyan"/>
								<h3 className="team__name mt-3">Syuzanna Hakhverdyan</h3>
								<p className="team__ocupation">Quality Assurance</p>
							</div>
							<div className="team__member text-center">
									<img className="team__avatar" src={Hak} alt="Hakob Mesropyan" />
									<h3 className="team__name mt-3">Hakob Mesropyan</h3>
									<p className="team__ocupation">Front-end developer</p>
							</div>

							<div className="team__member text-center">
								<img className="team__avatar" src={Lus} alt="Lusine Mesropyan"/>
								<h3 className="team__name mt-3">Lusine Mesropyan</h3>
								<p className="team__ocupation">UX/UI designer</p>
							</div>

							{/* <div className="team__member text-center">
								<img className="team__avatar" src={tranMau} alt="team member pic"/>
								<h3 className="team__name mt-3">Jeniffer Martinez</h3>
								<p className="team__ocupation">Digital Development</p>

								<div className="follow-us__social-links">
									<a href="#"><i className="fab fa-youtube"></i></a>
									<a href="#"><i className="fab fa-instagram"></i></a>
									<a href="#"><i className="fab fa-linkedin-in"></i></a>
								</div>
							</div>
							
							<div className="team__member text-center">
									<img className="team__avatar" src={miguelangelMiq} alt="team member pic" />
									<h3 className="team__name mt-3">Juan David Rendon</h3>
									<p className="team__ocupation">Digital Development</p>

									<div className="follow-us__social-links">
										<a href="#"><i className="fab fa-youtube"></i></a>
										<a href="#"><i className="fab fa-instagram"></i></a>
										<a href="#"><i className="fab fa-linkedin-in"></i></a>
									</div>
							</div> */}
						</div>
					</div>
					
					<div className="row">
						<hr className="light-color" />
					</div>

					<div className="what-we-do">
						<div className="what-we-do--">
							<h4>we specialize in:</h4>
						</div>
						
						<div className="what-we-do__icon">
							<img src={icon2} alt="Interactive Design" />
							<h4 className="what-we-do__title">Front-end development</h4>
						</div>
						
						<div className="what-we-do__icon">
							<img src={icon1} alt="code" />
							<h4 className="what-we-do__title">Quality testing</h4>
						</div>
						
						<div className="what-we-do__icon">
							<img src={icon3} alt="Illustration" />
							<h4 className="what-we-do__title">Illustration</h4>
						</div>
						
						{/* <div className="what-we-do__icon">
							<img src={icon4} alt="Interactive Design" />
							<h4 className="what-we-do__title">Interactive Design</h4>
						</div> */}
						
						<div className="what-we-do__icon">
							<img src={icon5} alt="UX/UI" />
							<h4 className="what-we-do__title">UX/UI</h4>
						</div>
					</div>

					<div className="row d-flex justify-content-center">
						<div className="col-lg-9">
							<div className="technologies">
								<div className="d-flex align-items-center justify-content-center flex-wrap">
									<img src={iconHtml} alt="HTML" className="mx-3 my-2" width="40" />
									<img src={iconCss} alt="CSS" className="mx-3 my-2" width="40" />
									<img src={iconJs} alt="JS" className="mx-3 my-2" width="41" />
									<img src={iconBs} alt="Bootstrap" className="mx-3 my-2" width="45" />
									<img src={iconReact} alt="React" className="mx-3 my-2" width="50" />
									<img src={iconRedux} alt="Redux" className="mx-3 my-2" width="40" />
									<img src={iconNextjs} alt="nextJs" className="mx-3 my-2" width="90" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutSection;