import React from 'react';
import HomeSection from '../../components/Sections/HomeSection';
import ProcessSection from '../../components/Sections/ProcessSection';
import ServicesSection from '../../components/Sections/ServicesSection';
import AboutSection from '../../components/Sections/AboutSection';
import ContactSection from '../../components/Sections/ContactSection';
import Footer from '../../components/Footer/Footer';

const Home = () => {
	return (
		<main>
			{/* <!-- Home --> */}
			<HomeSection />
			
			{/* <!-- Works --> */}
			<ProcessSection />

			{/* <!-- Services --> */}
			<ServicesSection />

			{/* <!-- About --> */}
			<AboutSection />
			
			{/* <!-- Contact --> */}
			<ContactSection />

			{/* <!-- Footer --> */}
			<Footer />
		</main>
	);
};

export default Home;